<template>
  <div
    class="modal fade"
    data-bs-backdrop="static"
    tabindex="-10"
    id="kt_modal_view_news"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-uppercase d-flex align-items-center"></h5>
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="border: 0px"
          >
            <i class="bi bi-x-lg"></i>
          </div>
        </div>
        <div class="modal-body">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <div class="pb-1 fs-1">Comunicazione</div>
              <hr />
              <div class="fs-5" v-html="testoAlert"></div>
              <!---->
            </div>
            <!----><!---->
          </div>
        </div>
        <div class="modal-footer justify-content-end">
          <!----><button
            type="button"
            class="me-5 btn btn-light-success rounded fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Ok, ho capito
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  onBeforeMount,
  watch,
} from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "kt-menu",
  setup() {
    const store = useStore();
    return { testoAlert: computed(() => store.getters.testoAlert) };
  },
});
</script>
<style scoped>
.modal-dialog {
  max-width: 50% !important;
}
</style>
