
import { defineComponent, computed, onMounted, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
/*import KTCreateApp from "@/components/modals/wizards/CreateAppModal.vue";
import KTDemosDrawer from "@/layout/extras/DemosDrawer.vue";
import KTHelpDrawer from "@/layout/extras/HelpDrawer.vue"; */
/* import KTToolButtons from "@/layout/extras/ToolButtons.vue"; */
/* import KTDrawerMessenger from "@/layout/extras/MessengerDrawer.vue"; */
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import { useMsal } from "@/composition-api/useMsal";
import { loginRequest } from "@/authConfig";
import Comunicazione from "@/components/components-fit/bacheca/Comunicazione.vue";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
    KTUserMenu,
    Comunicazione,
    /*  KTCreateApp,

    KTDemosDrawer,
    KTHelpDrawer,
    KTToolButtons,
    KTDrawerMessenger, */
    KTLoader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const isMenuLoaded = computed(() => store.getters.isMenuLoaded);
    // if (!isMenuLoaded.value) store.dispatch("getMenuFromApi", 0);

    onMounted(() => {
      nextTick(() => {
        reinitializeComponents();
      });

      // Simulate the delay page loading
      // setTimeout(() => {
      //   // Remove page loader after some time
      //   store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      // }, 500);
      // }
    });
    watch(isMenuLoaded, () => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      isMenuLoaded,
      statusMenu: computed(() => store.getters.statusMenu),
      isAuthenticated,
      user: computed(() => store.getters.user),
    };
  },
});
