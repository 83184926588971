<template>
  <!--begin::Toolbar-->
  <div class="toolbar" id="kt_toolbar">
    <!--begin::Container-->
    <div
      id="kt_toolbar_container"
      :class="{
        'container-fluid': toolbarWidthFluid,
        'container-xxl': !toolbarWidthFluid,
      }"
      class=""
    >
      <!--begin::Page title-->
      <div
        data-kt-swapper="true"
        data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        class="page-title align-items-center me-3 mb-5 mb-lg-0"
      >
        <div class="row d-flex">
          <div class="col-7 d-flex justify-content-between pe-0">
            <h1
              class="align-items-center fw-bolder my-2 fs-3 page-title"
              style="align-self: center"
            >
              {{ title }}
            </h1>
            <span
              v-if="!stagioneCorrente"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              style="align-self: center; white-space: normal !important"
            >
              Attenzione! si sta operando sulla stagione {{ defaultStagione }}
            </span>
          </div>

          <div class="col-md-3 ps-0" style="align-self: center">
            <div class="text-end fw-bold my-2" v-if="title != 'Dashboard'">
              <span> Stagione di riferimento </span>
            </div>
          </div>
          <div class="col-md-2 pe-0" style="align-self: center">
            <div v-if="title != 'Dashboard'">
              <!--  <span> Stai agiendo sulla stagione </span> -->
              <select
                class="form-select form-select-sm form-select-season"
                aria-label=".form-select-sm example"
                @change="changeStagione"
                :disabled="pendingRequests > 0"
              >
                <option
                  v-for="stagione in stagioni"
                  :key="stagione.id"
                  :value="stagione.id"
                  :selected="stagione.id === defaultStagione"
                >
                  {{ stagione.label }}
                </option>
                <!-- <option selected>2022</option>
                <option>2021</option>
                <option>2020</option> -->
              </select>
            </div>
          </div>
        </div>
      </div>

      <!--end::Page title-->

      <!--begin::Actions-->
      <!-- <div class="d-flex align-items-center py-1"> -->
      <!--begin::Wrapper-->
      <!-- <div class="me-4"> -->
      <!--begin::Menu-->
      <!--  <a
            href="#"
            class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <inline-svg src="media/icons/duotune/general/gen031.svg" />
            </span>
            Filter
          </a> -->

      <!--   <Dropdown1></Dropdown1> -->
      <!--end::Menu-->
      <!--  </div> -->
      <!--end::Wrapper-->

      <!--begin::Button-->
      <!--  <a
          href="#"
          class="btn btn-sm btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_create_app"
          id="kt_toolbar_primary_button"
        >
          Create
        </a> -->
      <!--end::Button-->
      <!-- </div> -->
      <!--end::Actions-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Toolbar-->
</template>

<script>
const debugPrint = false;
import { defineComponent, computed, ref } from "vue";
/* import Dropdown1 from "@/components/dropdown/Dropdown1.vue"; */
import { toolbarWidthFluid } from "@/core/helpers/config";

import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
    path: String,
  },
  components: {
    /*    Dropdown1, */
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const defaultStagione = computed(() => store.getters.defaultStagione);
    const stagioneCorrente = computed(() => store.getters.stagioneCorrente);

    const checkStagioneCorrente = () => {
      if (defaultStagione.value == new Date().getFullYear()) {
        store.commit("setStagioneCorrente", true);
      } else store.commit("setStagioneCorrente", false);
    };

    //cambio stagione
    const changeStagione = (e) => {
      store.commit("setStagioneSelected", parseInt(e.target.value));
      store.commit("setStagione", parseInt(e.target.value));
      store.commit("setStagioneSocieta", parseInt(e.target.value));
      store.commit("setStagioneDefault", parseInt(e.target.value));
      store.commit("setRowsToSkipDocSoc");
      store.commit("setRowsToSkipAlbo");
      store.commit("setRowsToSkipAffiliazioni");
      store.commit("setRowsToSkipEsportazioni");
      store.commit("setRowsToSkipQuote");
      store.commit("setRowsToSkipHPAnagrafiche");
      store.commit("setRowsToSkipDettaglioImportazione");
      store.commit("setRowsToSkipImportazioni");
      store.commit("setRowsToSkipAffiliazioni");
      store.commit("setRowsToSkipAffiliazioni");
      store.commit("setRowsToSkipRanking");
      store.commit("setRowsToSkipSanzioni");
      store.commit("setRowsToSkipAlboSnm");
      store.commit("setRowsToSkipCorsi");
      store.commit("setRowsToSkipCorsoPartecipanti");
      store.commit("setRowsToSkipCorsiAreaInsegnante");
      store.commit("setRowsToSkipSocieta");
      store.commit("setRowsToSkipDocSoc");
      store.commit("setRowsToSkipEconomatoSoc");
      store.commit("setRowsToSkipRichiesteUnaSoc");
      store.commit("setRowsToSkipRinnovo");
      store.commit("setRowsToSkipTesserato");
      store.commit("setRowsToSkipImpAss");
      store.commit("setRowsToSkipTrasfRichieste");
      store.commit("setRowsToSkipTesseratoFederale");
      store.commit("setRowsToSkipTesseratoSoc");
      store.commit("setRowsToSkipTrasf");
      store.commit("setRowsToSkipVivaio");
      store.commit("setRowsToSkipReportPretesseramento");
      checkStagioneCorrente();

      // if (route.path === "/societa") store.dispatch("getSocietaList");
    };
    return {
      toolbarWidthFluid,
      stagioni: computed(() => store.getters.stagioni),
      defaultStagione,
      changeStagione,
      checkStagioneCorrente,
      stagioneCorrente,
      pendingRequests: computed(() => store.getters.numberOfAjaxCAllPending),
    };
  },
});
</script>

<style>
@media (min-width: 992px) {
  .toolbar-fixed .toolbar {
    /* position: fixed; */
    position: inherit;
    border-top: 1px solid #eff2f5;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 5%);
    z-index: 100px;
  }
}

@media (min-width: 992px) {
  .content {
    padding: 0 0;
  }
}

@media (max-width: 992px) {
  .toolbar-fixed .toolbar {
    display: none;
  }
}
</style>
